<template>
  <div>
    <DxPopup
      :title="popUpTitle"
      :visible="visible"
      :drag-enabled="false"
      :show-title="true"
      :close-on-outside-click="true"
      :show-close-button="true"
      :width="'90%'"
      minHeight="30%"
      maxHeight="70%"
      @hidden="hidden"
    >
      <DxList
        ref="receiversList"
        :pullRefreshEnabled="false"
        height="100%"
        :selectByClick="true"
        selectionMode="none"
        :focusStateEnabled="false"
        :hoverStateEnabled="false"
        :activeStateEnabled="false"
        :bounceEnabled="true"
        :scrollByThumb="true"
        :scrollByContent="true"
        :useNativeScrolling="true"
        :data-source="receiversDataSource"
      >
        <template #item="{ data: item }">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span style="flex-grow: 1; font-weight: 700; font-size: 1.25em">
              {{ item.brandCode }}
              <i
                v-if="isBrandSkipped(item)"
                class="dx-icon-clearsquare"
                style="color: #ee4729"
              ></i>
            </span>
            <div>
              <DxButton
                icon="rowfield"
                @click="handleShowActivityDetail(item)"
              />
              <DxButton
                icon="paste"
                type="default"
                @click="handleScanItems(item)"
              />
              <DxButton
                icon="selectall"
                type="success"
                :disabled="item.isCompleted"
                @click="handleCompleteTask(item)"
              />
            </div>
          </div>
        </template>
      </DxList>
    </DxPopup>
    <ActivityActions
      :visible="isActivityActionsVisible"
      :hidden="handleHideActivityActions"
      :selected-plan="selectedPlan"
    />
  </div>
</template>
<script>
import util from "../../commons/utility";
import service from "../../commons/service";
import DxPopup from "devextreme-vue/popup";
import DxList from "devextreme-vue/list";
import DxButton from "devextreme-vue/button";
import ActivityActions from "./activity-actions.vue";
import DataSource from "devextreme/data/data_source";
import Pod from "./pod.vue";
import { truckMode } from "../../commons/constants";
import utility from "../../commons/utility";

export default {
  props: {
    siteId: String,
    receiversParentSeq: { type: String, default: null },
    tasks: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    truckMode: {
      type: String,
      default: truckMode.LOAD,
    },
    hidden: Function,
  },
  data() {
    return {
      selectedPlan: null,
      isActivityActionsVisible: false,
      selectedTasks: [],
      taskItems: [],
      receiversDataSource: new DataSource({
        paginate: false,
        onChanged: this.handlePlansDataChange,
        load: () => {
          const completedTask = util.getCompletedTasks();

          const taskIds = this.tasks.map((element) => element.GuidTaskID);

          const promise = service.getTaskLinesByTaskIds(taskIds, true);

          promise.then((response) => {
            this.tasksItems = response.data ?? [];

            const summarizedData = [];
            for (const key of Object.keys(this.taskByBrands)) {
              const children = this.taskByBrands[key];

              let isAllChildrenCompleted = true;

              for (var child in children) {
                const task = children[child];
                const taskSeq = task.Seq;

                const isCompleted = completedTask.includes(taskSeq);

                if (!isCompleted) {
                  isAllChildrenCompleted = false;
                  break;
                }
              }

              summarizedData.push({
                brandCode: key,
                children: children,
                isCompleted: isAllChildrenCompleted,
              });
            }
            response.data = summarizedData;
          });

          return promise;
        },
      }),
    };
  },
  computed: {
    taskBrandByTask(task) {
      try {
        const brandCode = task.TaskID;
        return brandCode.split("_")[1];
      } catch (e) {
        return null;
      }
    },
    taskByBrands() {
      let taskByBrands = [];
      try {
        taskByBrands = this.tasks.reduce((groups, task) => {
          let taskBrandCode = task.TaskID;

          taskBrandCode = taskBrandCode.split("_")[1].replace("-", "");
          groups[taskBrandCode] = groups[taskBrandCode] || [];
          groups[taskBrandCode].push(task);

          return groups;
        }, {});
      } catch (e) {
        return [];
      }
      return taskByBrands;
    },
    popUpTitle() {
      const tasks = this.tasks;
      if (tasks == null || tasks.length <= 0) return "";
      return tasks[0].Sites.Description;
    },
  },
  methods: {
    isBrandSkipped(receiver) {
      const tasks = receiver.children ?? [];
      let isBrandSkipped = false;

      tasks.forEach((task) => {
        if (utility.isTaskSkipped(task.Seq)) {
          isBrandSkipped = true;
          return isBrandSkipped;
        }
      });

      return isBrandSkipped;
    },
    handleShowActivityDetail(item) {
      this.selectedPlan = item.children[0];
      this.isActivityActionsVisible = true;
    },
    handleCompleteTask(item) {
      this.selectedPlan = item.children[0];

      this.selectedTasks = item.children.filter((element) =>
        element.TaskID.includes(item.brandCode)
      );

      this.$router
        .push({
          name: "pod-v2",
          params: {
            pageTitle: this.popUpTitle,
            truckMode: this.truckMode,
            tasks: this.selectedTasks,
            ...(this.receiversParentSeq
              ? { selectedTaskSeq: this.receiversParentSeq }
              : null),
          },
        })
        .catch(() => {});
    },
    handleScanItems(item) {
      this.selectedPlan = item.children[0];

      this.selectedTasks = item.children.filter((element) =>
        element.TaskID.includes(item.brandCode)
      );

      this.$router
        .push({
          name: "truck-load-unload",
          params: {
            truckMode: this.truckMode,
            tasks: this.selectedTasks,
            ...(this.receiversParentSeq
              ? { selectedTaskSeq: this.receiversParentSeq }
              : null),
          },
        })
        .catch(() => {});
    },
    handleHideActivityActions() {
      this.isActivityActionsVisible = false;
    },
  },
  components: {
    DxPopup,
    DxList,
    DxButton,
    ActivityActions,
  },
};
</script>
<style lang="scss"></style>

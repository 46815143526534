<template>
  <div>
    <h2 class="content-block">{{ pageTitle }}</h2>
    <div class="content-block">
      <div
        class="dx-card responsive-paddings"
        style="display: flex; flex-direction: column"
      >
        <div class="dx-field">
          <div class="dx-field-key">
            <DxButton
              icon="back"
              type="default"
              @click="handleBackToActivityPlansListBtnClick"
            />
          </div>
        </div>
        <DxDataGrid
          :showColumnLines="true"
          :showRowLines="true"
          :showBorders="true"
          :data-source="receiverItemsDataSource"
          :editing="{
            allowAdding: false,
            allowUpdating: false,
            allowDeleteing: false,
          }"
        >
          <DxColumn
            :caption="$t('pod.clmn_receiver')"
            data-field="receiver"
            :group-index="0" />
          <DxColumn caption="SSCC" data-field="SSCC" />
          <DxColumn
            :caption="$t('pod.clmn_count')"
            width="20%"
            :calculate-cell-value="calculateCountColumnValue" />
          <DxColumn
            :caption="$t('pod.clmn_done')"
            width="20%"
            cell-template="done-cell"
            alignment="center" />
          <template #done-cell="{ data: rowData }">
            <div>
              <i
                v-if="isAllItemsReceived(rowData.data)"
                class="dx-icon-check"
                style="color: green"
              />
              <i
                v-if="!isAllItemsReceived(rowData.data)"
                class="dx-icon-close"
                style="color: red"
              />
            </div>
          </template>
          <DxGroupPanel :visible="false" />
          <DxGrouping :auto-expand-all="true" />
          <DxPaging :page-size="15" />
          <DxPager :visible="true" />
          <DxSearchPanel :visible="true"
        /></DxDataGrid>
        <canvas
          id="signature-pad"
          ref="signaturePad"
          class="sign-canvas"
          width="300px"
          height="300px"
          style="margin-top: 2em"
        />
        <div style="margin-top: 0.5em; align-self: center">
          <DxButton icon="trash" type="danger" @click="handleClearSignature" />
          <DxButton icon="revert" type="default" @click="handleUndoSignature" />
        </div>
        <DxTextArea
          :placeholder="$t('common.hint_comment')"
          max-length="100"
          v-model="commentValue"
          style="margin-top: 2em"
        />
        <ImagePickerButton
          ref="btn-take-photo"
          width="100%"
          icon="photo"
          :buttonText="$t('common.btn_take_picture')"
          :disabled="false"
          :imageResult.sync="photoResult"
          @uploaded="handlePhotoUploaded"
          style="margin-top: 1em"
        />
        <DxButton
          class="button-save-pod"
          width="100%"
          :disabled="!isValid || isLoading"
          @click="handleSaveSignature"
          type="success"
          :text="$t('common.btn_save')"
          icon="check"
          style="margin-top: 1em"
        />
      </div>
    </div>
    <DxLoadPanel
      :visible="isLoading"
      :message="$t('common.lbl_loading')"
      :position="{ of: '#content-container' }"
      :close-on-outside-click="false"
    />
  </div>
</template>

<script>
import api from "../../commons/api";
import service from "../../commons/service";
import util from "../../commons/utility";
import { useGeneralStore } from "../../stores/generalStore";
import i18n from "../../i18n";
import { truckMode } from "../../commons/constants";
import SignaturePad from "signature_pad";
import DxButton from "devextreme-vue/button";
import DataSource from "devextreme/data/data_source";
import DxTextArea from "devextreme-vue/text-area";
import ImagePickerButton from "../../components/image-picker-button";
import {
  DxDataGrid,
  DxColumn,
  DxGroupPanel,
  DxPaging,
  DxPager,
  DxSearchPanel,
  DxGrouping,
} from "devextreme-vue/data-grid";
import DxLoadPanel from "devextreme-vue/load-panel";

export default {
  data() {
    return {
      truckMode: truckMode.LOAD,
      tasks: [],
      receiverItems: [],
      signaturePad: null,
      isScrollViewDisabled: false,
      receiverItemsDataSource: new DataSource({
        key: "ItemID",
        paginate: false,
        onChanged: this.handlePlansDataChange,
        load: () => {
          const childTaskIds = this.tasks.map((element) => element.GuidTaskID);

          const promise = service.getTaskLinesByTaskIds(childTaskIds, true);

          promise.then((response) => {
            const remoteItems = [...(response.data ?? [])];
            this.receiverItems = remoteItems;

            const items = remoteItems.map((e) => ({
              receiver: e.ItemID.split("_")[1],
              ...e,
            }));

            response.data = items;
          });

          return promise;
        },
      }),

      photoResult: null,
      commentValue: "",
      isLoading: false,
    };
  },
  mounted() {
    const tasks = this.$route.params
      ? this.$route.params.tasks
        ? this.$route.params.tasks
        : []
      : [];
    this.tasks = tasks;

    const truckMode = this.$route.params
      ? this.$route.params.truckMode
        ? this.$route.params.truckMode
        : truckMode.LOAD
      : truckMode.LOAD;

    this.truckMode = truckMode;

    const canvas = this.$refs["signaturePad"];

    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: "lightgray",
      penColor: "blue",
    });

    this.signaturePad.addEventListener(
      "beginStroke",
      () => {
        if (!this.isScrollViewDisabled) this.isScrollViewDisabled = true;
      },
      { once: false }
    );

    this.signaturePad.addEventListener(
      "endStroke",
      () => {
        if (this.isScrollViewDisabled) this.isScrollViewDisabled = false;
      },
      { once: false }
    );
  },
  computed: {
    pageTitle() {
      const siteDesc = this.$route.params
        ? this.$route.params.pageTitle
          ? this.$route.params.pageTitle
          : ""
        : "";
      if (siteDesc === "") return "POD";

      return `POD - ${siteDesc}`;
    },
    siteID() {
      const passedTasks = this.tasks;
      if (passedTasks == null || passedTasks == 0) return "";

      return passedTasks[0].SiteID;
    },
    selectedTaskSeq() {
      const routeParams = this.$route.params;
      return routeParams["selectedTaskSeq"];
    },
    isCameraReady() {
      return (
        navigator !== undefined &&
        navigator !== null &&
        navigator.camera !== undefined &&
        navigator.camera !== null
      );
    },
    isValid() {
      if (this.signaturePad == null || this.signaturePad.isEmpty())
        return false;

      if (this.isCameraReady && this.photoResult == null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleBackToActivityPlansListBtnClick() {
      this.$router
        .replace({
          name: "activity-plan",
          query: {
            ...(this.selectedTaskSeq != null
              ? { selectedTaskSeq: this.selectedTaskSeq }
              : null),
          },
        })
        .catch(() => {});
    },
    calculateCountColumnValue(rowData) {
      const scannedQty = this.getScannedQty(rowData);
      return `${scannedQty} / ${rowData.Original_Qty}`;
    },
    isAllItemsReceived(rowData) {
      const scannedQty = this.getScannedQty(rowData);
      return scannedQty >= rowData.Original_Qty;
    },
    handleUndoSignature() {
      let data = this.signaturePad.toData();
      if (data) {
        data.pop();
        this.signaturePad.fromData(data);
      }
    },
    handleClearSignature() {
      this.signaturePad.clear();
    },
    handleCancelSignature() {
      this.handleClearSignature();
    },
    async handleSaveSignature() {
      const actionName = "Task Completed";

      // TODO Raymon
      const store = useGeneralStore();
      const isOnline = store.online;

      const currentLat = store.currentLat;
      const currentLon = store.currentLon;

      // Keep for debugging purpose
      // alert(JSON.stringify({ currentLat, currentLon }));
      // console.log("currentLatLon", { currentLat, currentLon });
      // console.log("receiverItems", this.receiverItems);

      if (!isOnline) {
        this.isLoading = true;
        setTimeout(() => {
          this.tasks.forEach((task) => {
            this.saveTaskDataInLocal(task, this.receiverItems);
          });

          this.$root.showSuccess(this.getSuccessMessage(actionName), 2000);
          this.handleBackToActivityPlansListBtnClick();
          this.resetState();
          this.isLoading = false;
        }, 3000);

        return;
      }

      // Value1 =
      // Value2 =
      // Value3 = comment
      // Value4 =
      // Value5 = photo
      // Value6 = odometer
      // Value7 = signature

      const siteID = this.siteID;

      const taskIds = [
        ...new Set(
          this.tasks
            .filter((e) => !this.isTaskCompletedOrSkipped(e))
            .map((e) => e.TaskID)
        ),
      ];

      // Kepp for debug purpose
      // console.log("tasks", this.tasks);
      // console.log("receiverItems", this.receiverItems);
      // console.log("taskIds", taskIds);

      if (siteID == null) return;

      if (taskIds == null || taskIds.length <= 0) {
        this.isLoading = true;
        setTimeout(() => {
          this.tasks.forEach((task) => {
            this.saveTaskDataInLocal(task, this.receiverItems);
          });

          this.$root.showSuccess(this.getSuccessMessage(actionName), 2000);
          this.handleBackToActivityPlansListBtnClick();
          this.resetState();
          this.isLoading = false;
        }, 3000);
        return;
      }

      const requests = [];
      for (const taskId of taskIds) {
        const param = {
          ResourceID: util.getSelectedResourceId(),
          AllocationID: util.getSelectedAllocationId(),
          SiteID: siteID,
          LogType: actionName,
          Gps_Time: util.getCurrentDateStr(),
          Value3: this.commentValue,
          Latitude: currentLat,
          Longitude: currentLon,
          TaskID: taskId,
        };

        const promise = this.getLogRequest(api.POST_LOG_URL, param);
        requests.push(promise);
      }

      this.isLoading = true;

      let logIds = await Promise.all(requests);
      logIds = logIds.filter((logId) => logId != null);

      if (logIds == null || logIds.length <= 0) {
        // error
        this.isLoading = false;
        this.$root.showError("Error", 2000);
        return;
      }

      this.tasks.forEach((task) => {
        this.saveTaskDataInLocal(task, this.receiverItems);
      });

      if (this.photoResult) {
        try {
          await this.$http({
            method: "post",
            url: api.POST_RESOURCE_LOGS_IMAGE_URL,
            params: {
              LogID: logIds.join(","),
              Type: "Value5",
            },
            data: Buffer.from(this.photoResult.split(";base64,")[1], "base64"),
            headers: { "Content-Type": "image/jpeg" },
          });
        } catch (e) {
          // IGNORED
        }
      }

      if (this.signaturePad.isEmpty()) {
        this.isLoading = false;
        this.$root.showSuccess(
          this.getSuccessMessage(actionName, logIds.length),
          2000
        );
        this.handleBackToActivityPlansListBtnClick();
        this.resetState();
        return;
      }

      const signatureImage = this.signaturePad.toDataURL("image/jpeg");

      this.$http({
        method: "post",
        url: api.POST_RESOURCE_LOGS_IMAGE_URL,
        params: {
          LogID: logIds.join(","),
          Type: "Value7",
        },
        data: Buffer.from(signatureImage.split(";base64,")[1], "base64"),
        headers: { "Content-Type": "image/jpeg" },
      })
        .then((resp) => {
          this.$root.showSuccess(
            this.getSuccessMessage(actionName, logIds.length),
            2000
          );
          this.handleBackToActivityPlansListBtnClick();
          this.resetState();
          this.isLoading = false;
        })
        .catch((err) => {
          this.$root.showSuccess(
            this.getSuccessMessage(actionName, logIds.length),
            2000
          );
          this.handleBackToActivityPlansListBtnClick();
          this.resetState();
          this.isLoading = false;
        });
    },
    getScannedQty(item) {
      if (this.truckMode == truckMode.LOAD) {
        return item.Load_Qty;
      } else if (this.truckMode == truckMode.UNLOAD) {
        return item.Unload_QtY;
      }

      return 0;
    },
    handlePhotoUploaded(data) {},
    isTaskCompletedOrSkipped(task) {
      return util.isTaskCompleted(task.Seq) || util.isTaskSkipped(task.Seq);
    },
    saveTaskDataInLocal(task, receiverItems) {
      const currentreceiverItems = receiverItems.filter(
        (taskItem) => taskItem.TaskID === task.GuidTaskID
      );

      let isAnyItemSkipped = false;
      currentreceiverItems.forEach((currentTaskItem) => {
        const qty = currentTaskItem.Original_Qty;
        const scannedQty = this.getScannedQty(currentTaskItem);

        if (scannedQty < qty) {
          // skip this task
          util.saveSkippedTask(task.Seq);
          isAnyItemSkipped = true;
        }
      });

      if (!isAnyItemSkipped) {
        util.saveCompletedTask(task.Seq);
      }
    },
    resetState() {
      this.signaturePad.clear();
      this.photoResult = null;
      this.commentValue = "";
    },
    getLogRequest(url, param) {
      const promise = new Promise((resolve, _) => {
        this.$http
          .post(url, param)
          .then((response) => {
            const logId = response.data.LogID;
            resolve(logId);
          })
          .catch((_) => resolve(null));
      });

      return promise;
    },
    getSuccessMessage(actionName, logCount = 0) {
      const translatedActionName = i18n.t(
        `dictionaries.${actionName.replace(" ", "_")}`
      );

      if (logCount > 0) {
        return `${i18n.t("pod.msg_success", {
          actionName: translatedActionName,
          logCount: logCount,
        })}`;
      } else {
        return `${i18n.t("pod.msg_success_empty", {
          actionName: translatedActionName,
        })}`;
      }
    },
  },
  components: {
    DxButton,
    ImagePickerButton,
    DxTextArea,
    DxDataGrid,
    DxColumn,
    DxGroupPanel,
    DxPaging,
    DxPager,
    DxSearchPanel,
    DxGrouping,
    DxLoadPanel,
  },
};
</script>
